
// Required config must be added to avoid unused env from being tree-shaked

const buildtimeConfig = {
  ACG_ENV: process.env.ACG_ENV,
  ACG_DOMAIN: process.env.ACG_DOMAIN,
  ACG_ORG_ID: process.env.ACG_ORG_ID,
  ACG_CHECKOUT_DOMAIN: process.env.ACG_CHECKOUT_DOMAIN,
  ACG_TEAMS_DOMAIN: process.env.ACG_TEAMS_DOMAIN,
  ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID,
  ALGOLIA_STAGE: process.env.ALGOLIA_STAGE,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN, 
  AUTH0_CUSTOM_DOMAIN: process.env.AUTH0_CUSTOM_DOMAIN,
  AUTH0_API_AUDIENCE: process.env.AUTH0_API_AUDIENCE, 
  BUGSNAG_API_KEY: process.env.BUGSNAG_API_KEY,
  BUGSNAG_RELEASE_STAGE: process.env.BUGSNAG_RELEASE_STAGE,
  BUILD_NUMBER: process.env.BUILD_NUMBER,
  CLOUDINARY_ACCOUNT: process.env.CLOUDINARY_ACCOUNT,
  CLOUDINARY_BASE_URL: process.env.CLOUDINARY_BASE_URL,
  COMMIT_HASH: process.env.COMMIT_HASH,
  DEFAULT_ROUTE: process.env.DEFAULT_ROUTE,
  GOOGLE_ANALYTICS_TRACKING_ID: process.env.GOOGLE_ANALYTICS_TRACKING_ID,
  GOOGLE_TAG_MANAGER_CONTAINER_ID: process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID,
  GRAPHQL_API_ENDPOINT: process.env.GRAPHQL_API_ENDPOINT,
  SEGMENT_API_KEY: process.env.SEGMENT_API_KEY,
  SPLIT_IO_API_KEY: process.env.SPLIT_IO_API_KEY,
  ACG_LEARN_DOMAIN: process.env.ACG_LEARN_DOMAIN,
  APP_VERSION: process.env.APP_VERSION,
  APPCUES_TEAMS_ONBOARDING_FLOW_ID: process.env.APPCUES_TEAMS_ONBOARDING_FLOW_ID,
  APPCUES_ADD_USER_TO_TEAM_FLOW_ID: process.env.APPCUES_ADD_USER_TO_TEAM_FLOW_ID,
  ACG_SKILLS_ASSESSMENT_DOMAIN: process.env.ACG_SKILLS_ASSESSMENT_DOMAIN,
  ACG_PATH_BUILDER_FRAGMENT_APP: process.env.ACG_PATH_BUILDER_FRAGMENT_APP,
  RENEWAL_EMAIL: process.env.RENEWAL_EMAIL,
  SHOW_DRIFT_CHAT: process.env.SHOW_DRIFT_CHAT
};

module.exports = {
  getConfig: () => {
    return buildtimeConfig;
  }
};
